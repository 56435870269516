* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  font-family: 'Heebo';
  font-size: 18px;
  color: #1f0039;
}

.home-container {
  min-height: calc(100vh - 64px)
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    -webkit-appearance: none;
    overflow: hidden;
    width: 100%;
    transform: rotate(180deg);
    height: 7px;
    border-radius: 11.3px;
    background-color: white;
  }


  .mux-video {
    --seek-backward-button: none;
    --seek-forward-button: none;
    /* --media-object-fit: contain; */
    --time-display: all;
  }

  .mux-setting {
    --media-object-fit: cover;
    --live-button: none;
    --seek-backward-button: none;
    --seek-forward-button: none;
    --mute-button: none;
    --captions-button: none;
    --airplay-button: none;
    --pip-button: none;
    --fullscreen-button: none;
    --cast-button: none;
    --playback-rate-button: none;
    --volume-range: none;
    --time-range: none;
    --time-display: none;
    --duration-display: none;
    --rendition-selectmenu: none;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 9.3px;
    height: 9.3px;
    border-radius: 50%;
    cursor: pointer;
    background: white;

    box-shadow: -200px 0 0 200px #d9d9d9
  }

}




.video-mask {
  z-index: 1;
  border: 2px solid black;
  margin: 70px 0 0.6px 39.8px;
  padding: 0 0px 0 1.2px;
  border-radius: 9.4px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 46%, #000 100%);
}


.video {
  margin: 50px 0px 50px 0px;
  width: auto;
  display: flex;
  padding: 11px;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
  border-radius: 16px;
  box-shadow: 10px 14px 64px 0 rgba(47, 32, 114, 0.08);
  background-color: #fff;
}

.profile-card {
  min-height: 96px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40vw;
  margin-top: 12px;
  padding: 32px;
  border-radius: 8px;
  border: solid 1px #d4daf8;
  background-color: #fff;
}

.account {
  position: absolute;
  width: 240px;
  height: auto;
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 50px;
  right: 22px;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 10px 40px 0 rgba(74, 71, 113, 0.2);
  border: solid 1px #d4daf8;
  background-color: #fff;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  position: absolute;
  top: -10px;
  right: 152px;
  bottom: 10px;
  z-index: 1000;
  border-bottom: 13px solid white;
}

.notif {
  width: 440px;
  height: auto;
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 60vh;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 10px 40px 0 rgba(74, 71, 113, 0.2);
  background-color: #fff;
}

.react-player video {
  position: relative;
  left: 0;
  top: 0;
  border: 10px solid;
  transform: none;
}



.mask-video {
  width: 372px;
  height: 246.8px;
  bottom: 0;
  margin-bottom: 11px;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.time {
  width: 77px;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  top: 40px;
  right: 40px;
  position: absolute;
  padding: 4px 16px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
}

.portrait-crop {
  height: 460px;
  object-fit: cover;
  object-position: top;
  width: 100vw;
}

.video-card {
  width: 348px;
  height: 626px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 0;
  margin: 17.6px 28px 17.6px 0;
  border-radius: 14.3px;
  background-color: #fafafa;
}




.progress-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 0;
}

.progress {
  border-radius: 4px;
  width: 100%;
  height: 4.6px;
  display: flex;
  background-size: 100%;
  background-color: #d1d6e0;
}






.dashboard {
  overscroll-behavior-y: contain;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  position: sticky;
  user-select: none;
  width: 100%;
  overflow: hidden;
}

.image-frame {
  width: 300px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 20px;
  border-radius: 8px;
  border: solid 1px #d4daf8;
  background-color: #fff;
}


.company-frame {
  width: 627px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-top: 10px;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #d4daf8;
  background-color: #fbfcff;

}

.cards {
  height: 101px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 500px;
  padding: 20px 32px;
  opacity: 0.8;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 16px -1px rgba(225, 225, 234, 0.5);
  background-color: #fff;
}

.cardContent {
  width: 100%;
  height: 100%;
}


.file_button_container input {
  opacity: 0;
  position: absolute;
  width: calc(20vw - 12px);
  padding: 20px 10px 20px 0;

}

.decoration {
  text-decoration-color: #36c0b0 !important;

}

.edit_button input {
  opacity: 0;
  position: absolute;
  width: 28px;
  height: 28px;
}

.swipe-card {
  position: relative;
  background-color: #fff;
  /* width: 400px;
  height: 722px; */
  border-radius: 15px;
  background-size: cover;
  background-position: center;
}

.date-mask {
  max-width: 190px;
  width: 59%;
  height: 48px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  border: solid 1px #d4daf8;
  background-color: #fff;

}

.date-mask input {
  width: 30%;
  border: 0px;
  text-align: center;
}

.icon::placeholder {
  padding-right: 24px !important;
}

.icon-container {
  cursor: pointer;
  width: 120px;
  text-align: center;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 16px -1px rgba(207, 204, 217, 0.3);
  background-color: #fff;
}

.date-mask input::placeholder {
  font-size: 16px;
  color: #827aa5;
}

.date-mask input:focus {
  outline: none !important;
}

.line {
  width: 1px;
  height: 32px;
  flex-grow: 0;
  background-color: #d4daf8;
}

.swipe-card {
  /* width: 400px;
  height: auto; */
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  display: flex;
  text-align: center;
}



::-webkit-scrollbar {
  width: 8px !important;
  background-color: 'transparent' !important;
}

.swipe {
  position: absolute;
}

.swiper-img {
  border-radius: 9.4px;
  display: flex;
  height: auto;
  width: 403px;
  height: 740px;
  justify-content: center;
  align-items: center;
  position: relative;
}


.gradient {
  width: 100%;
  opacity: 0.46;
  pointer-events: all;
  position: absolute;
  height: 150px;
  bottom: 0;
  z-index: 1000;
  border-radius: 9.4px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%);
}

.blur-view {
  width: 100%;
  opacity: 0.46;
  pointer-events: all;
  position: absolute;
  height: 100%;
  border-radius: 9.4px;
  background-color: black;
}

.cardContainer {
  height: auto;
  display: flex;
  padding: 0px 120px 0px 120px;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
}

.card {

  background-size: cover;
  background-position: center;
  width: 400px;
  min-height: 722px;
  height: 'auto';
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  display: flex;
  text-align: center;
}


.cardContent {
  width: 100%;
  height: 100%;
}


.title {
  font-size: 20px;
  color: #2F279D;
  font-weight: 800;
}

.side-bar,
.side-bar-user {
  background-color: white;
  display: flex;
  width: 300px;
  height: 100%;
  padding: 20px 20px 0px 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.side-bar main {
  display: flex;
  width: 300px;
  flex-direction: column;
}

.side-bar-user main {
  display: flex;
  width: 300px;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
}

.candidate {
  height: 52px;
  margin: 0 0 15px;
  border-radius: 8px;
  padding: 7px 7px 6px 13px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f7f5ff;
}

.title-hover {
  cursor: pointer;
}

.title-hover:hover {
  text-decoration: underline;
}

.side-bar span {
  font-size: 14px;
  padding-top: 10px;
  text-align: center;
}

.side-bar-user span {
  font-size: 14px;
  text-align: right;
}

.side-menu {
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  border-radius: 32px 0px 0px 0px;
  background-color: #2f2072;
}

.side-menu div {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
}

.side-menu span {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.title-user {
  font-size: 18px;
  color: #321B8E;

}

.team {
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  padding: 10px;
  margin-top: 5px;
  text-align: right;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
}

.team:hover {
  padding: 10px;
  border-radius: 8px;
  background-color: #f4f6ff;
}

.team .login {
  display: none;
}

.team:hover .login {
  display: block;
}

.login-screen {
  margin-top: 186px;
  margin-right: 186px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.interview-card {
  width: 430px;
  height: 326px;
  flex-grow: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 5px 16px -1px rgba(225, 225, 234, 0.5);
  border: solid 1px #d4daf8;
  background-color: #fff;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar {
  width: 360px;
  height: 280px;
  flex-grow: 0;
  align-items: center;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 60px 0 rgba(47, 39, 156, 0.1);
  background-color: #fff;
}

.react-calendar__navigation {
  text-align: center;
  margin-bottom: 8px;
}


.react-calendar__navigation button {
  border: 0px;
  background-color: white;
}

.react-calendar__navigation__label {
  font-family: 'Heebo';
  background-color: white;
  border: 0px;
  color: #2f2072;
  font-size: 16px;
}

.react-calendar__month-view__weekdays__weekday {
  height: 32px !important;
  width: 32px !important;
  color: #827aa5;
  font-weight: bold;
  flex: none !important;
  text-align: center !important;
  margin-inline-end: 10px !important;

}

.react-calendar__month-view__days {
  /* width: 230px; */
  border: 0px solid black;

}

.react-calendar__year-view__months button,
.react-calendar__decade-view__years button {
  background-color: transparent;
  border: 0px;
}

.react-calendar__month-view__days button {
  background-color: white;
  margin-inline-end: 10px !important;
  border: 0px;
  border-radius: 50% !important;
  height: 32px !important;
  width: 32px !important;
  flex: none !important;
}


.react-calendar__viewContainer {
  font-family: 'Heebo';
  color: '#827aa5';
  font-size: 14.2px;
  width: 290px;
  display: flex;
  text-decoration: none;
}

.react-calendar__tile react-calendar__month-view__days__day {
  font-family: 'Heebo';
  color: '#2f2072';
  font-size: 14.2px;
}

.react-calendar__tile--now {
  background-color: white !important;
  color: #827aa5;
}

.react-calendar__tile--active,
.react-calendar__tile--range {
  border-radius: 50% !important;
  height: 32px !important;
  width: 32px !important;
  font-weight: bold;
  flex: none !important;
  border: 1px solid #5233d9 !important;
  background-color: white !important;
  /* Change this to your desired background color */
  color: #2f2072 !important;
  /* Change this to your desired text color */
}

.job-screen {
  margin-top: 60px;
  margin-right: 7vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.success-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.date {
  width: 360px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  color: #827aa5;
  border: solid 1px #5233d9;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 26px;
  height: 64px;
  left: 0px;
  z-index: 1;
  position: relative;
  top: 0px;
  box-shadow: 0 2px 60px 0 rgba(47, 39, 156, 0.1);
  background-color: #fff;
}

.divider {
  height: 32px;
  width: 0px;
  border: 1px solid #a9a9be;
}

.text-input {
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  width: 320px;
  color: #1f0039;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #D4DAF8;
  border-radius: 8px;
  text-align: right;

}

.dropdown-status {
  width: 189px;
  height: 112px;
  position: absolute;
  display: flex;
  top: 35px;
  gap: 20px;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 18px 10px;
  border-radius: 8px;
  box-shadow: 0 5px 16px -1px rgba(225, 225, 234, 0.5);
  border: solid 1px #d4daf8;
  background-color: #fff;
}



.text-area {
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
  padding-left: 80px;
  width: 40vw;
  color: #1f0039;
  height: 100px;
  background: #FFFFFF;
  border: 1px solid #D4DAF8;
  border-radius: 8px;
  text-align: right;

}

.text-area:focus {
  outline: none !important;
  border: 1px solid #4f35be;
}

.text-area::placeholder {
  color: #1f0039;
  opacity: 0.5;
}

.interview:hover {
  background-color: #f8f8ff;
}

.interview {
  height: 114px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 26px;
  align-items: center;
  gap: 32px;
  position: relative;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 5px 16px -1px rgba(225, 225, 234, 0.5);
  background-color: #fff;

}

.video-cover {
  width: 556.8px;
  height: auto;
  color: #d0cce2;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 50px;
  text-align: right;
  padding: 46.5px 50.8px 0px 50.8px;
  background-color: #2f2072;
}

.text-input:focus {
  outline: none !important;
  border: 1px solid #4f35be;
}

.text-input::placeholder {
  color: #1f0039;

}

.jobs-card {
  width: 32%;
  height: 90px;
  display: flex;
  flex-direction: row;
  color: #2f2072;
  align-items: center;
  gap: 12px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 16px -1px rgba(207, 204, 217, 0.2);
  border: solid 1px #edebf8;
  background-color: #fff;
}

.seperator {
  margin: 32px 0px 0px 0px;
  background-color: #d4daf8;
  height: 1px;
  max-width: 655px;
  align-self: stretch;

}

.frame-interview {
  height: 44px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px 4px 0px;
  border-radius: 8px;
  border: solid 1px #d4daf8;
  background-color: rgba(212, 218, 248, 0.1);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

/* Style to hide the clock icon in Firefox */
input[type="time"] {
  -moz-appearance: textfield;
}

.list-wrapper {
  position: relative;
}

.list-item-wrapper {
  margin-top: 14px;
  position: relative;
}

.list-bullet {
  float: right;
  margin-left: 20px;
  background: #fd7e50;
  height: 11px;
  width: 11px;
  border-radius: 50%;
}

.list-item {
  display: table-row;
}


.list-text {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

.red-line {
  margin-top: 0px;
  background: #fd7e50;
  z-index: 1;
  width: 1.2px;
  height: 125%;
  position: absolute;
  right: 5px;
}

.filter {
  width: 498px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: -64px;
  right: 0px;
  min-height: 100vh;
  z-index: 1;
  position: absolute;
  padding: 24px 24px 40px;
  box-shadow: -6px 0 25px -1px rgba(109, 111, 124, 0.5);
  background-color: #fff;
}



.chat-title {
  width: auto;
  padding: 5px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #7b7b7b;
  margin-top: 24px;
  font-size: 16px;
  border-radius: 70px;
  background-color: #fff;
}

.chat-bubble {
  width: 100%;
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  margin-top: 24px;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
}

.menusidebar {
  width: 15vw;
  right: 17px;
  height: auto;
  display: flex;
  text-align: right;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 64px 24px;
  box-shadow: 0 5px 16px -1px rgba(225, 225, 234, 0.5);
  background-color: #fff;
}

.chatmenu {
  width: 22%;
  position: relative;
  display: flex;
  text-align: right;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
}

.message__chats_me {
  padding: 16px;
  border-radius: 4px;
  width: 55%;
  margin-top: 32px;
  background-color: #f1ffef;
  text-align: right;
  display: flex;
  gap: 12px;
}

.message__chats_you {
  padding: 16px;
  border-radius: 4px;
  width: 55%;
  margin-top: 32px;
  align-items: flex-end;
  align-self: flex-end;
  background-color: #ffffff;
  flex-direction: column;
  display: flex;
  gap: 5px;
}

.sender__name {
  font-weight: bold;
}

.message__sender {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.chat-container {
  background-color: #f1f3f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
}

.no-chat {
  background-color: #f1f3f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
  width: 78%
}

.message-container {
  height: calc(100vh - 120px);
}

.filters {
  position: absolute;
  top: -100;
  z-index: 1000;
  text-align: right;
  display: flex;
  align-items: center;
  right: 280px;
}

.pause {
  opacity: 0;
  transition: opacity .35s ease;


}

.swiper-img:hover .pause,
.setting-video:hover .pause {
  opacity: 1;
}

.match-img:hover .pause {
  opacity: 1;
}

.setting-frame {
  width: 300px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 20px;
  border-radius: 8px;
  border: solid 1px #d4daf8;
  background-color: #fff;
}

.profile-frame {
  margin-top: 25px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 21.5px 43.1px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 4px 16px -1px #f2f2f2;
  background-color: #fff;
}

.profile-frame .title,
.company-frame .title {
  font-size: 20px;
  color: #554a75;
  font-weight: bold;
}

.profile-frame .text {
  font-size: 16px;
  font-weight: bold;
}

.profile-frame .subtitle {
  color: #827aa5;
  font-size: 16px;

}

.progress-circle {
  background-color: #fd7e50;
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.progress-line {
  background-color: #fd7e50;
  width: 3px;
  height: 50px;
}

.Field {
  width: 515px;
  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  margin-top: 57px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 12px 10px 6px;
  border-radius: 8px;
  border: solid 1px #d4daf8;
  background-color: #fff;
}

.Field input:focus {
  outline: none;

}

.match {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  z-index: 1000;
  background-image: linear-gradient(153deg, rgba(232, 79, 152, 0) 22%, #3312c9 118%);
}

.filter-tag {
  width: fit-content;
  height: 36px;
  flex-grow: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  padding: 10px 18px;
  border-radius: 30px;
  box-shadow: 0 4px 16px -1px rgba(207, 204, 217, 0.4);
  border: solid 1px #4f35be;
  background-color: #fff;
}

.rectangle {
  width: 260px;
  padding: 12px 20px;
  border-radius: 8px;
  height: auto;
  background-color: #fff;
  /* Adjust color as needed */
}

.rectangle span:first-child {
  font-size: 14px;
  font-weight: bold;
}

.rectangle span {
  font-size: 12px;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 12px solid #fff;
}

.auto-complete {
  padding: 10px;
  position: relative;
  margin-top: 10px;
  width: 320px;
  color: #1f0039;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #D4DAF8;
  border-radius: 8px;
  text-align: right;

}

.auto-complete:focus {
  outline: none !important;
  border: 1px solid #4f35be;
}

.auto-complete::placeholder,
.text-area::placeholder {
  color: #827aa5;
}

.button {
  width: 150px;
  height: 44px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #4f35be;
  color: #fff;
  margin-left: 6px;
  font-weight: bold
}

.menu {
  padding: 10px;
  width: 320px;
  max-height: 150px;
  overflow-y: auto;
  box-sizing: border-box;
  position: absolute;
  z-index: 2000;
  color: #1f0039;
  background: #FFFFFF;
  border: 1px solid #D4DAF8;
  border-radius: 8px;
  text-align: right;
}

.menu::-webkit-scrollbar {
  width: 8px;
}

.menu::-webkit-scrollbar-thumb {
  background: #827aa5;
  border-radius: 11px;
}

.menu::-webkit-scrollbar-track {
  background: transparent;
}

.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 19px;
  height: 19px;
  border-radius: 4px;
  border: 1px solid #d4daf8;
  outline: none;
  cursor: pointer;
}

input[type='checkbox']:checked {
  background-image: url("./assets/images/check-2.png");
  background-size: 70%;
  background-position: center;
  background-color: #4f35be;
  background-repeat: no-repeat;
}

.popup {
  display: flex;
  justify-content: center;
  width: 580px;
  /* height: 428px; */
  position: absolute;
  top: 50%;
  z-index: 2;
  padding: 24px 32px 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-shadow: 0 4px 25px -1px rgba(109, 111, 124, 0.5);
  background-color: #fff;
}

.two-factor:focus {
  outline: none !important;
  border: 1px solid #4f35be !important;
}

.success-button1 {
  width: 200px
}

.success-button2 {
  width: 116px
}


.terms {
  cursor: pointer;
  text-decoration: underline;
}

.upload {
  width: 40vw;
  justify-content: space-between;
}

.dotted {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D4DAF8FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
}

.frame {
  display: flex;
  width: calc(20vw - 12px);
  /* min-width: 320px; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 10px 20px 0;
  background-color: rgba(241, 239, 245, 0.5);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D4DAF8FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
}

.dialog {
  display: flex;
  justify-content: center;
  width: 580px;
  height: auto;
  position: absolute;
  top: 50%;
  z-index: 2;
  padding: 40px 24px 40px 24px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-shadow: 0 4px 25px -1px rgba(109, 111, 124, 0.5);
  background-color: #fff;
}



.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 1;
  left: 0;
  position: fixed;
}

.hyphen {
  background-color: #827aa5;
  height: 2px;
  width: 15px
}

.home {
  display: flex;
  justify-content: center;
}

.card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

}

.no-matches {
  width: 100%;
  height: 86px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 24px;
  padding: 12px 24px;
  border-radius: 8px;
  border: solid 1px #d4daf8;
  background-color: #f8f8ff;
}


.circle {
  width: 32.7px;
  height: 32.7px;
  border-radius: 21.3px;
  border: solid 2.2px #4f35be;
  align-items: center;
  display: flex;
  background-color: #4f35be;
}

.step>* {
  margin-left: 16px;
}

.menu-card {
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 120px;
  height: 130px;
  margin-left: 24px;
  border-radius: 4px;
  box-shadow: 0 4px 16px -1px rgba(207, 204, 217, 0.2);
  background-color: #fff;
}



.menu-card:hover {
  box-shadow: 0px 5px 16px -1px rgba(74, 71, 113, 0.2);
}

.experience {
  width: 140px;
  height: 44px;
  padding: 10px 24px;
  margin: 32px 0px 0px 12px;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
  color: #2f2072;
  box-shadow: 0 4px 16px -1px rgba(207, 204, 217, 0.2);
  background-color: #fff;
}

.skills {
  width: fit-content;
  color: #FFFFFF;
  margin-top: 32px;
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 18px;
  border-radius: 30px;
  box-shadow: 0 4px 16px -1px rgba(207, 204, 217, 0.4);
  border: solid 1px #4f35be;
  background-color: #4f35be;
}

.tag {
  height: 55px;
  padding: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 12px;
  font-size: 12px;
  color: #2f2072;
  box-shadow: 0 4px 16px -1px rgba(207, 204, 217, 0.3);
  background-color: #fff;
}

.div-container {
  display: flex;
  margin-top: 12px;
  align-items: center;
  flex-wrap: wrap;
  /* border: 1px solid; */
  /* padding-bottom: 30px; */
  width: 40vw;
}


.tag-mobile {
  height: 50px;
  width: fit-content;
  padding: 16px 10px 16px 10px;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 12px 0px 15px 12px;
  font-size: 12px;
  color: #2f2072;
  box-shadow: 0 4px 16px -1px rgba(207, 204, 217, 0.4);
  background-color: #fff;
}

.experience:hover,
.tag:hover {
  box-shadow: 0px 5px 16px -1px rgba(74, 71, 113, 0.2);
}


.msg-wrapper {
  white-space: pre-wrap;
}

.skill {
  margin: 5px;
  width: fit-content;
  height: 33px;
  display: flex;
  margin-left: 8px;
  align-items: center;
  justify-content: space-between;
  color: #2f2072;
  padding: 6px 16px;
  font-size: 14px;
  font-family: 'Heebo';
  border-radius: 30px;
  border: solid 1px #827aa5;
}

.skill-2 {
  width: 40px;
  height: 33px;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Heebo';
  padding: 6px 16px;
  border-radius: 30px;
  background-color: #4f35be;
}



@media only screen and (max-width: 1170px) {

  .icon-container {

    height: 117px;
    padding: 10px 12px;

  }

  .chat-container {
    height: calc(100vh - 120px);
  }

  .message__chats_me,
  .message__chats_you {
    width: 90%;
  }

  .chatmenu {
    background-color: transparent;
    width: 100%;
  }

  .interview-card {
    width: 90%;
    height: auto;
    padding: 20px 16px;

  }

  .interview:hover {
    background-color: transparent;
  }

  .interview {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    padding: 12px;
  }

  .react-calendar {
    width: 100%;
  }

  .date {
    width: 100%;
  }

  .job-screen,
  .login-screen {
    margin-right: 30px;
  }

  .Field {
    width: 95%;
    height: 48px;
    flex-grow: 0;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    margin-top: 57px;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 2px 10px 0px;
    border-radius: 8px;
    border: solid 1px #d4daf8;
    background-color: #fff;
  }

  .player-wrapper {
    width: 300px;
    height: 210px;
  }

  .react-player {
    width: 100%;
    height: 100%;
  }

  .Field .button {
    font-size: 14px;

  }

  .frame-interview {
    height: 52px
  }

  .swiper-img {
    width: 100vw;
  }


  .cover {
    display: none;
  }

  .image-frame {
    width: calc(100vw - 60px)
  }

  .profile-card {
    width: 100%
  }

  .account {
    z-index: 1;
    left: 30px;
    top: 40px;
  }

  .mobile-menu {
    width: 308px;
    height: 100%;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 81px 32px 81px 16px;
    background-color: #2f2072;
  }

  .div-container {
    display: flex;
    margin-top: 12px;
    align-items: center;
    flex-wrap: wrap;
    /* border: 1px solid; */
    /* padding-bottom: 30px; */
    width: 85vw;
  }

  .sheet {
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin: 39px 1px 0 0;
    padding: 18px 27px;
    background-color: #2f2072;
  }

  .auto-complete {
    width: calc(100vw - 60px);
  }

  .menu {
    width: 85vw
  }

  .cards {
    width: calc(100vw - 55px);

  }

  .upload {
    width: calc(100vw - 55px);
    align-items: center;
    justify-content: space-between;
  }

  .frame {
    width: 100%;
    margin-top: 24px;
  }

  .file_button_container input {
    width: calc(100vw - 55px);

  }

  .video-card {
    margin-top: 16px !important;
    width: 300px;
    height: 217px;
    margin: 0 auto;
    padding: 58px 95.4px 51px 94.6px;
    border-radius: 10.9px;
  }

  .filter-mobile {
    height: '80%';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    border-radius: 8px;
    padding: 24px 24px 40px;
    background-color: #fff;
    direction: rtl;
  }

  .filters {
    position: absolute;
    top: 50px;
    left: -10px;
    right: 0;
    z-index: 1000;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .filters-mobile {
    margin-top: 20px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .rectangle {
    box-shadow: 0 -4px 16px -1px rgba(207, 204, 217, 0.5);
  }

  .company-frame {
    width: calc(100vw - 55px);
  }

  .company-frame .title,
  .profile-frame .title {
    font-size: 18px;
    color: #554a75;
    font-weight: bold;
  }

  .triangle {
    width: 0;
    height: 0;
    left: 20px;
    top: -14px;
    z-index: 1000;
    position: absolute;
    transform: rotate(270deg);

  }

  .triangle-sound {
    width: 0;
    height: 0;
    left: 31%;
    bottom: -10px;
    z-index: 1000;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #fff;
    position: absolute;
    transform: rotate(90deg);

  }

  .login-screen {
    width: calc(100vw - 55px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .skill-input:focus {
    outline: none !important;
    border: 0px
  }

  .pass {
    color: #8a5ceb !important;
  }

  .text-input {
    width: calc(100vw - 60px);
    /* max-width: 320px; */

  }

  .notif {
    width: 90vw;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: none;
    background-color: #fff;
  }

  .title-user {
    font-size: 24px;
    color: #321B8E;

  }


  .button {
    width: calc(100vw - 55px);
    margin-top: 10px;
    /* margin: 9px 0px 0px 0px !important; */
  }

  .setting-frame {
    width: 100%;
  }

  .header-mobile {
    padding: 15px 12px;
    height: 64px;
    z-index: 1000;
    left: 0px;
    align-items: center;
    display: flex;
    justify-content: center;
    top: 0px;
    background: #FFFFFF;
    box-shadow: 0px 2px 60px rgba(47, 39, 156, 0.1);
  }

  .step {
    margin: 0 auto;
    margin-top: 50px;
  }



  .popup {
    display: flex;
    justify-content: center;
    width: calc(100vw - 50px);
    position: absolute;
    top: 50%;
    padding: 24px 24px 40px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 0 4px 25px -1px rgba(109, 111, 124, 0.5);
    background-color: #fff;
  }


  .dialog {
    position: fixed;
    display: flex;
    width: calc(100vw - 55px);
    border-radius: 8px;
    box-shadow: 0 4px 25px -1px rgba(109, 111, 124, 0.5);
    background-color: #fff;
  }

  .job-cover {
    display: none;
  }


  .job-screen {
    width: calc(100vw - 55px);
    margin: 0 auto;
    display: flex;
    text-align: right;
    flex-direction: column;
  }

  .profile-frame {
    width: calc(100vw - 55px)
  }


  .video-screen {
    margin: 0 auto;
    display: flex;
    text-align: right;
    flex-direction: column;
  }

  .success-screen {
    width: calc(100vw - 55px);

  }

  .text-area {
    box-sizing: border-box;
    padding: 10px;
    margin-top: 10px;
    padding-left: 80px;
    width: calc(100vw - 60px);
    color: #1f0039;
    height: 155px;
    background: #FFFFFF;
    border: 1px solid #D4DAF8;
    border-radius: 8px;
    text-align: right;
  }

  .menu-card {
    margin-top: 20px;
    width: 148px;
    height: 140px;
    margin-left: 0px;

  }



  .mask-video {
    width: 100%;
    position: absolute;
    height: 246.8px;
    margin: 526.2px 0 0;
    padding: 48.3px 91px 52.4px 90.5px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  }

  .time {
    top: 60px;
    right: 30px;

  }

  .home-container {
    height: 100%;
  }

  .header-dashboard {
    height: 64px;
    padding: 0 16px;
    z-index: 1000;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: space-between;
    background: transparent;
  }

  .sound-tip {
    width: 270px;
    height: 56px;
    display: flex;
    text-align: right;
    left: 10px;
    z-index: 1000;
    bottom: 50px;
    position: absolute;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 12px;
  }

  .gradient-top {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    height: 200px;
    opacity: 0.5;
    width: 100%;
    z-index: 1000;
    position: absolute;
    top: 0
  }
}